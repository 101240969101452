import * as React from 'react';
import { Link } from "gatsby";
import * as styles from "./styles/button.module.css"


const Button = (props) => {

    //console.log(props);

    let classes = []

    if (props.variant == 'primary') {
        classes.push(styles.primary);
    }
    else if (props.variant == 'primaryInverse') {
        classes.push(styles.primaryInverse);
    }
    else if (props.variant == 'secondary') {
        classes.push(styles.secondary);
    }
    else if (props.variant == 'disabled') {
        classes.push(styles.disabled);
    }

    if (props.fullWidth == true) {
        classes.push(styles.fullWidth);
    }

    if (props.variant == 'disabled') {
        return (
            <div className={`${styles.button} ${classes.join(' ')}`}>
                {props.children}
            </div>
        );
    }

    if (props.action) {
        return (
            <button onClick={props.action} className={`${styles.button} ${classes.join(' ')}`}>
                {props.children}
            </button>
        );
    }

    if (props.extTo) {
        return (
            <a href={props.extTo} className={`${styles.button} ${classes.join(' ')}`}>
                {props.children}
            </a>
        );
    }

    return (
        <Link to={props.to} className={`${styles.button} ${classes.join(' ')}`}>
            {props.children}
        </Link>
    );


}

export default Button;