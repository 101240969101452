import * as React from 'react';
import * as styles from "./styles/contentContainer.module.css"

const ContentContainer = (props) => {

    return (
        <div className={`${styles.contentContainer}`}>
            {props.children}
        </div>
    );
}

export default ContentContainer;