import * as React from 'react';
import { Link } from "gatsby";
import ContentContainer from './contentContainer';
import assetLogo from '../assets/images/logo-md.svg';
import assetMenuIcon from '../assets/images/icon-menu.svg';
import assetMenuCloseIcon from '../assets/images/icon-menu-close.svg';
import * as styles from "./styles/mainMenu.module.css"


class MainMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    toggleMenu() {
        this.setState({ open: !this.state.open });
    }

    render() {

        let pathName = this.props.pathname;

        if (pathName.length > 1) {
            pathName = pathName.endsWith('/') ? pathName.slice(0, -1) : pathName;
        }

        return (
            <div className={`${styles.container}`}>
                <ContentContainer>
                    <div className={`${styles.innerContainer}`}>
                        <div className={`${styles.logoContainer}`}>
                            <Link to="/">
                                <img src={assetLogo} />
                            </Link>
                        </div>
                        <div className={`${styles.linksContainer}`}>
                            <Link to="/" className={`${styles.link} ${pathName == "/" ? styles.active : ""}`}>Home</Link>
                            <Link to="/products" className={`${styles.link} ${pathName == "/products" ? styles.active : ""}`}>What's Inside</Link>
                        </div>
                        <div className={`${styles.mobileMenuContainer}`}>
                            <a href="#" onClick={() => { this.toggleMenu(); }}>
                                {!this.state.open && <img src={assetMenuIcon} />}
                                {this.state.open && <img src={assetMenuCloseIcon} />}
                            </a>

                        </div>
                    </div>
                    {this.state.open && <div className={`${styles.mobileMenuLinksContainer}`}>
                        <Link to="/" className={`${styles.link} ${pathName == "/" ? styles.active : ""}`}>Home</Link>
                        <Link to="/products" className={`${styles.link} ${pathName == "/products" ? styles.active : ""}`}>What's Inside</Link>
                    </div>}
                </ContentContainer>
            </div>
        )
    }
}

export default MainMenu;