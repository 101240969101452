import * as React from 'react';
import ContentContainer from './contentContainer';
import SubscribeInput from './subscribeInput';
import { Link } from "gatsby";
import * as styles from "./styles/footer.module.css"

import assetIconAus from '../assets/images/icon-aus.svg'

const Footer = (props) => {
    return (
        <div className={`${styles.container} ` + (props.floatingBuffer ? styles.floatingBuffer : '')}>
            <ContentContainer>
                <p className={styles.support}>
                    <a className={`${styles.link}`} href="mailto:contact@kitstart.com.au">contact@kitstart.com.au</a>
                </p>
                <p className={`noGutterbottom ${styles.businessDetailsL1}`}>
                    <img className={`${styles.iconAus}`} src={assetIconAus} />
                    <span className={`${styles.ausCopy}`}>Aussie owned & operated</span>
                </p>
                <p className={`noGutterbottom ${styles.businessDetailsL2}`}>ABN 71 986 219 106 trading as Kitstart. Unaffiliated with any third-party brands. All trademarks, logos and brand names are the property of their respective owners.</p>

            </ContentContainer>
        </div>
    );
}

export default Footer;