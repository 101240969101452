import * as React from 'react';
import * as styles from "./styles/video.module.css"

const Video = (props) => {

    return (
        <div className={`${styles.container}`}>
            <video className={`${styles.video}`} poster={props.poster} controls>
                <source src={props.src} type="video/mp4" />
            </video>
        </div>
    );
}

export default Video;