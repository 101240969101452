import * as React from 'react';
import ContentContainer from "../components/contentContainer"
import * as styles from "./styles/heroBanner.module.css"

const HeroBanner = (props) => {

    return (
        <ContentContainer>
            <div className={`${styles.banner}`}>
                <ContentContainer>
                {props.children}
                </ContentContainer>
            </div>
        </ContentContainer>
    );
}

export default HeroBanner;