import * as React from 'react';
import Helmet from "react-helmet"
//import assetLogo from '../images/kitstart-logo.svg';
import "./styles/reset.css"
import "./styles/theme.css"

const Layout = (props) => {

    return (
        <>
        <Helmet>
            <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap" rel="stylesheet"/>
        </Helmet>
        <div id="rootLayout">
            {props.children}
        </div>
        </>
    );
}

export default Layout;